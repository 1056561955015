/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
import LocalTime from 'local-time';
import objectFitImages from 'object-fit-images';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';

import '../scss/storypark.scss';
import '../scss/storypark-overrides.scss';

const setLocaleInLocalTime = () => {
  const locale = navigator.language || navigator.userLanguage;
  if (locale !== 'en') {
    LocalTime.config.i18n[locale] = {
      date: {
        monthNames: window.StoryparkI18n.monthNames,
        abbrMonthNames: window.StoryparkI18n.abbrMonthNames,
      },
    };
  }
  LocalTime.config.locale = locale;
  LocalTime.start();
  // Setting on the window for coffeescript files which call LocalTime.run()
  window.LocalTime = LocalTime;
};

document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
  setLocaleInLocalTime();
});


